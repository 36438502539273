import { createStore } from 'redux'
import LanguageChangeReducer from './reducers/LanguageChangeReducer'

let preloadedState
let preferredLanguage;
if(typeof localStorage !== 'undefined'){
    preferredLanguage = localStorage.getItem('preferredLanguage')
}

if(preferredLanguage){
    preloadedState = {
        preferredLanguage: JSON.parse(JSON.stringify(preferredLanguage))
    }
}else{
    preloadedState = {
        preferredLanguage: 'eng'
    }
    if(typeof localStorage !== 'undefined')
        localStorage.setItem('preferredLanguage', 'eng')
}

const store = createStore(LanguageChangeReducer, preloadedState);

export default store