import React, { useState, useEffect, useContext } from 'react'
import FsLightbox from 'fslightbox-react';
import { UserContext } from '../layouts/UserContext';
// import LocalSession from './LocalSession'

function StephenDemo({location, errors}) {

  // LightBox Toggle State
  const [ca, setCA] = useState(false)

  const caFrame = (
    <div className="modal-contain">
      <iframe
        className="icontain-modal"
        src="https://meetings.hubspot.com/trish-cook/workhub-demo"
        width="1000px"
        height="1000px"
        scrolling="no"
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      />
    </div>
  )

  return (
    <div>
      <section className="payment_subscribe_area">
        <div className="container">
          <div className="row">
            <div className="payment_subscribe_info ">
              <div className="payment_subscribe_content col-lg-6">
                <h2>Still have questions?</h2>
                <p>Let our knowledgeable sales team give you a full product tour and answer any specific questions you may have.</p>
              </div>
              <div className="payment_subscribe_content col-lg-4 offset-lg-2" style={{ textAlign: 'center' }}>

                <h2>Book A Demo</h2>

                {/* CA TOGGLER */}

               <><button type="button"
                  onClick={() => setCA(!ca)}
                  onKeyDown={() => setCA(!ca)}
                  className="btn_hover btn_four demo_canada">
                Schedule</button>
                  <FsLightbox
                    toggler={ca}
                    sources={[caFrame]}
                  /></> 

              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default StephenDemo
