import React, { useState, useEffect, useContext } from 'react'
import FsLightbox from 'fslightbox-react';
import { UserContext } from '../layouts/UserContext';
// import LocalSession from './LocalSession'

function EvanDemo({location, errors}) {

    const [us, setUS] = useState(false)
  
    const usFrame = (
      <div className="modal-contain">
        <iframe
          className='icontain-modal'
          src="https://app.hubspot.com/meetings/workhub/meet-with-a-workhub-guru?embed=true"
          width="1000px"
          height="1000px"
          scrolling="no"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        />
      </div>
    )

  return (
    <div>
      <section className="payment_subscribe_area">
        <div className="container">
          <div className="row">
            <div className="payment_subscribe_info ">
              <div className="payment_subscribe_content col-lg-6">
                <h2>Want to know more?</h2>
                <p>Schedule a chat with our knowledgeable sales team and answer any specific questions you may have.</p>
              </div>
              <div className="payment_subscribe_content col-lg-4 offset-lg-2" style={{ textAlign: 'center' }}>

                <h2>Booking</h2>

               <><button type="button"
                  onClick={() => setUS(!us)}
                  onKeyDown={() => setUS(!us)}
                  className="btn_hover btn_four demo_us">
                    Calendar</button>
                  <FsLightbox
                    toggler={us}
                    sources={[usFrame]}
                  /></>

              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default EvanDemo