import React, { useEffect, useState } from 'react';
import './style.scss';
import store from '../../store';
import { navigate } from "gatsby"


export default function Dropdown({location}) {
  const [isActive, setIsActive] = useState(false);
  const [selected, setIsSelected] = useState(store.getState().preferredLanguage);

  const handleLanguageChange = (event) => {
    setIsSelected(event);

    if(typeof localStorage !== 'undefined'){
      localStorage.setItem('preferredLanguage', event);
    }
    store.dispatch({type: 'preferredLanguage', payload: event})

    /**
     * These are the only pages that have espaniol translations so far
     * if its not any one of these pages then navigate to the main page
     */
    if(event === 'esp' && (location !== '/' && location !== '/about/what-is-workhub/' && location !== '/features/' && location !== '/pricing/') ){
      navigate('/es')
    }
  }

  return (
    <div className="language-dropdown"  >
      <div className="dropdown"
        onClick={(e) => {setIsActive(!isActive)}}

        onMouseEnter={(e) => {
            setIsActive(true);
        }}

        onMouseLeave={(e) => {
            setIsActive(false)
        }}
      >
        <div
          className="dropdown-btn"
        >
          <span>{selected === 'esp' ? 'ES': 'EN'}</span>
          <img src='/app-icons/arrow_down.svg' className={isActive ? 'arrow_active':'arrow_inactive'} />
        </div>
        <div
          className="dropdown-content"
          style={{ height: isActive ? "81px" : "0px", pointerEvents:isActive ? "all" : "none" ,boxShadow: isActive? 'rgba(0, 0, 0, 0.2) 0px 30px 30px -10px': '' }}
        >
          <div
            onClick={()=>handleLanguageChange('eng')}
            className="item"
            style={{ opacity: isActive ? "1" : "0" }}

          >
            <img src="/app-icons/eng.svg"/>
            <span>English</span>
          </div>
          <div
            className="item"
            onClick={()=>handleLanguageChange('esp')}
            style={{ opacity: isActive ? "1" : "0" }}
          >
            <img src="/app-icons/esp.svg"/>
            <span>Español</span>
          </div>
        </div>
      </div>
    </div>
  );
}
